





































import { defineComponent, PropType, watch } from '@vue/composition-api';
import VueSlider from 'vue-slider-component';

import useZoomPicker from './useZoomPicker';

export default defineComponent({
  name: 'ZoomPicker',
  components: {
    VueSlider,
  },
  props: {
    value: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { model, zoomIn, zoomOut, setZoom } = useZoomPicker();

    watch(
      () => props.value,
      (val) => {
        setZoom(val);
      },
    );

    watch(
      () => model.zoom.current,
      (zoom) => {
        if (zoom !== Math.round(props.value)) {
          emit('zoom', zoom);
        }
      },
    );

    return {
      model,
      zoomIn,
      zoomOut,
      setZoom,
    };
  },
});
