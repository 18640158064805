import { ref, Ref, watch } from '@vue/composition-api';
import { Subject } from 'rxjs';

import { MapEvent } from 'ol';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import View from 'ol/View';

import EChartsLayer from '@/lib/ol-echarts';

import { MAP_ZOOM_LIMITS } from '@/constants';
import BinnedMapChart, { RawBinnedData } from '@/utils/binnedMapChart';
import { ShareObject } from '@/services/shareService';

export default function useTheEchartsLayerObserver(
  mapContainerElement: Ref<HTMLDivElement | undefined>,
  onChartClick: (params: {
    dataIndex: number;
    // long, lat, value
    value: [number, number, number];
  }) => void,
): {
  movestart$: Subject<MapEvent>;
  moveend$: Subject<MapEvent>;
  init: (config: ShareObject) => void;
  draw: (data: RawBinnedData) => Promise<unknown>;
  clearSelection: () => void;
  onZoom: (zoom: number) => void;
} {
  let mapRef: Map | null = null;

  const movestart$ = new Subject<MapEvent>();
  const moveend$ = new Subject<MapEvent>();

  const onMoveStart = (event: MapEvent) => {
    movestart$.next(event);
  };

  const onMoveEnd = (event: MapEvent) => {
    moveend$.next(event);
  };

  const onZoom = (zoom: number) => {
    mapRef?.getView().setZoom(zoom / 10);
  };

  const init = (config: ShareObject) => {
    if (!mapRef && mapContainerElement.value) {
      mapRef = new Map({
        controls: [],
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        target: mapContainerElement.value,
        view: new View({
          projection: 'EPSG:4326',
          minZoom: MAP_ZOOM_LIMITS[0] / 10,
          maxZoom: MAP_ZOOM_LIMITS[1] / 10 + 0.001,
          center: [config.long, config.lat],
          zoom: config.zoom / 10,
        }),
      });

      mapRef.on('movestart', onMoveStart);
      mapRef.on('moveend', onMoveEnd);
    }
  };

  watch(mapContainerElement, (target, _, onInvalidate) => {
    if (!target) return;

    onInvalidate(() => {
      mapRef?.un('movestart', onMoveStart);
      mapRef?.un('moveend', onMoveEnd);
      mapRef = null;
    });
  });

  // Chart
  const binnedMapChart = new BinnedMapChart();

  // Echarts layer
  const echartsLayer = ref<EChartsLayer | null>(null);

  const redrawFromCache = () => {
    if (binnedMapChart.cachedOptions && echartsLayer.value) {
      echartsLayer.value.setChartOptions(binnedMapChart.cachedOptions);
    }
  };

  const clearSelection = () => {
    binnedMapChart.selectData(null);
    redrawFromCache();
  };

  const draw = (data: RawBinnedData) => {
    if (!mapRef) {
      return Promise.resolve();
    }
    const opt = binnedMapChart.createOptions(data);

    if (!echartsLayer.value) {
      echartsLayer.value = new EChartsLayer(opt, {
        hideOnMoving: true,
        forcedRerender: false,
        forcedPrecomposeRerender: false,
        stopEvent: false,
      });
      echartsLayer.value.appendTo(mapRef);

      echartsLayer.value.$chart.on('click', (params: any) => {
        binnedMapChart.selectData(params.dataIndex);
        // Redraw after selection
        redrawFromCache();
        onChartClick(params);
      });
    } else {
      echartsLayer.value.setChartOptions(opt);
    }
    // Drawing process takes time (sometimes more than expected). The proper event from echarts is 'finished'
    return Promise.resolve();
  };

  return {
    movestart$,
    moveend$,
    init,
    draw,
    clearSelection,
    onZoom,
  };
}
