

























































import { defineComponent, PropType } from '@vue/composition-api';
import { XIcon } from 'vue-feather-icons';

import HistoryPlot from '@/pages/StressMapPage/components/HistoryPlot';

import useTheInfoArea from './useTheInfoArea';

export default defineComponent({
  name: 'TheInfoArea',
  props: {
    parentContainerId: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  components: {
    XIcon,
    HistoryPlot,
  },
  setup(props) {
    const { model, close } = useTheInfoArea();

    const closeAndScrollTop = () => {
      // TODO: Make delay depend on actual scroll event?
      const { container, offset, delay } = (() => {
        const defaultConf = { container: window, offset: window.scrollY, delay: 0 };

        if (!props.parentContainerId) {
          return defaultConf;
        }

        const mapWrapper = document.getElementById(props.parentContainerId) as HTMLDivElement | null;
        if (mapWrapper) {
          return { container: mapWrapper, offset: mapWrapper.scrollTop, delay: 200 };
        }

        return defaultConf;
      })();
      if (offset === 0) {
        close();
      } else {
        container.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(close, delay);
      }
    };

    const afterEnter = () => {
      window.scrollTo({ top: 9999, behavior: 'smooth' });
    };

    return {
      model,
      close: closeAndScrollTop,
      afterEnter,
    };
  },
});
