type Geotiff = {
  valuesRange: [number, number] | null;
  cellSize: number | null;
};

export default function geotiff() {
  const model: Geotiff = {
    valuesRange: null,
    cellSize: null,
  };

  const updateValuesRange = (range: [number, number]) => {
    model.valuesRange = range;
  };

  const updateCellSize = (size: number) => {
    model.cellSize = size;
  };

  return {
    model: {
      geotiff: model,
    },
    actions: {
      geotiff: {
        updateValuesRange,
        updateCellSize,
      },
    },
  };
}
