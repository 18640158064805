import { v4 as uuidv4 } from 'uuid';

import { Palette, PaletteName } from '@/types/data';
import { PIECES_COUNT_RANGE } from '@/constants';

type Chart = {
  opacity: number;
  piecewise: {
    raw: {
      count: {
        current: number;
      };
      filter: {
        range: [number, number] | undefined;
      };
    };
    validated: {
      count: {
        current: number;
        min: number;
        max: number;
      };
      filter: {
        range: [number, number] | undefined;
      };
    };
    palette: {
      current: Palette;
      all: Palette[];
    };
  };
};

const palettes: Palette[] = [
  {
    hash: uuidv4(),
    colors: [
      '#FFFEE6',
      '#F6F7CC',
      '#E5F0BC',
      '#CDE6AA',
      '#BADD9D',
      '#8DC781',
      '#70B66D',
      '#64AA64',
      '#5D975C',
      '#558755',
    ],
    name: 'Grass',
  },
  {
    hash: uuidv4(),
    colors: [
      '#F4FAE1',
      '#EFFAD2',
      '#E1F7CE',
      '#CFF0CE',
      '#BDEAD1',
      '#ACE4D5',
      '#9DDED8',
      '#8CD8DC',
      '#7CCDDE',
      '#6FB9D9',
    ],
    name: 'Wave',
  },
  {
    hash: uuidv4(),
    colors: [
      '#285289',
      '#3C6FAA',
      '#5892CF',
      '#89B9E4',
      '#D3E5F2',
      '#FEFCED',
      '#FEF5A2',
      '#F7C95F',
      '#EE7636',
      '#CA5229',
    ],
    name: 'Cinematic',
  },
];

export default function chart(config: { opacity: number; pieces: number; palette: PaletteName }) {
  const model: Chart = {
    // Integer value, range 0..100
    opacity: config.opacity,
    piecewise: {
      raw: {
        count: {
          current: config.pieces,
        },
        filter: {
          range: undefined,
        },
      },
      validated: {
        count: {
          current: config.pieces,
          min: PIECES_COUNT_RANGE[0],
          max: PIECES_COUNT_RANGE[1],
        },
        filter: {
          range: undefined,
        },
      },

      palette: {
        current: palettes.find((el) => el.name === config.palette)!,
        all: palettes,
      },
    },
  };

  const updateOpacity = (value: number) => {
    model.opacity = value;
  };

  const updateRangeFilter = (range: [number, number]) => {
    model.piecewise.validated.filter.range = range;
  };

  const updatePiecesCount = (count: number) => {
    model.piecewise.validated.count.current = count;
  };

  const updateRawRangeFilter = (range: [number, number]) => {
    model.piecewise.raw.filter.range = range;
  };

  const updateRawPiecesCount = (count: number) => {
    model.piecewise.raw.count.current = count;
  };

  const updatePalette = (palette: Palette) => {
    model.piecewise.palette.current = palette;
  };

  return {
    model: {
      chart: model,
    },
    actions: {
      chart: {
        updateOpacity,
        updateRangeFilter,
        updatePalette,
        updateRawRangeFilter,
        updatePiecesCount,
        updateRawPiecesCount,
      },
    },
  };
}
