type Overview = {
  center: {
    long: number;
    lat: number;
  };
  zoom: number;
};

export default function overview(config: { long: number; lat: number; zoom: number }) {
  const model: Overview = {
    center: {
      long: config.long,
      lat: config.lat,
    },
    zoom: config.zoom,
  };

  const update = (nextState: Overview) => {
    model.zoom = nextState.zoom;
    model.center.long = nextState.center.long;
    model.center.lat = nextState.center.lat;
  };

  return {
    model: {
      overview: model,
    },
    actions: {
      overview: {
        update,
      },
    },
  };
}
