import { InjectionKey, reactive, readonly } from '@vue/composition-api';

import ShareService from '@/services/shareService';
import chart from '@/store/domain/chart';
import geotiff from '@/store/domain/geotiff';
import sources from '@/store/domain/sources';
import overview from '@/store/domain/overview';
import area from '@/store/domain/area';
import overviewLoader from '@/store/ui/overviewLoader';

const initConfig = ShareService.getDefaultConfig();

// Modules
const chartModule = chart({ opacity: initConfig.opacity, pieces: initConfig.pieces, palette: initConfig.palette });
const geotiffModule = geotiff();
const sourcesModule = sources(initConfig.sources);
const overviewModule = overview({ zoom: initConfig.zoom, long: initConfig.long, lat: initConfig.lat });
const areaModule = area();

const overviewLoaderModule = overviewLoader();

const model = reactive({
  // Domain
  ...chartModule.model,
  ...geotiffModule.model,
  ...sourcesModule.model,
  ...overviewModule.model,
  ...areaModule.model,
  // UI
  ...overviewLoaderModule.model,
});

const store = {
  init: readonly({
    share: initConfig,
  }),
  model: readonly(model),
  actions: {
    // Domain
    ...chartModule.actions,
    ...geotiffModule.actions,
    ...sourcesModule.actions,
    ...overviewModule.actions,
    ...areaModule.actions,
    // UI
    ...overviewLoaderModule.actions,
  },
  services: {
    share: ShareService,
  },
};

export const StoreKey: InjectionKey<typeof store> = Symbol('StoreKey');

export default store;
