

































import { defineComponent } from '@vue/composition-api';

import DataSourcePicker from '@/pages/StressMapPage/components/DataSourcePicker';
import OpacityPicker from '@/pages/StressMapPage/components/OpacityPicker';
import RangePicker from '@/pages/StressMapPage/components/RangePicker';
import BaseSpinner from '@/components/BaseSpinner';
import useTheInfoArea from '@/pages/StressMapPage/components/TheInfoArea/useTheInfoArea';

import useSettingsWidget from './useSettingsWidget';

export default defineComponent({
  name: 'SettingsWidget',
  components: {
    DataSourcePicker,
    OpacityPicker,
    RangePicker,
    BaseSpinner,
  },
  setup() {
    const { model } = useSettingsWidget();
    const { model: theInfoAreaModel } = useTheInfoArea();
    return {
      model,
      theInfoAreaModel,
    };
  },
});
