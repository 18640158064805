/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion */
import { DataLayer, Extent, GeoBoxBounds, RasterExtent } from '@/types/data';
import GridBuilder from '@/utils/gridBuilder';
import { TOTAL_GRID_CELL_NUMBER } from '@/constants';

import { fromUrl } from 'geotiff';

type FileDirectory = {
  GDAL_METADATA: string;
  GDAL_NODATA: string;
};

type TiffImage = {
  getWidth: () => number;
  getHeight: () => number;
  readRasters: (options: {
    width?: number;
    height?: number;
    window?: number[];
    resampleMethod?: string;
    resX?: number;
    resY?: number;
  }) => [number[]];
  getBoundingBox: () => [number, number, number, number];
  fileDirectory: FileDirectory;
};

type TiffFile = {
  getImage: (index?: number) => Promise<TiffImage>;
  getImageCount: () => number;
};

export default class COGExtractor {
  private TOTAL_NUMBER_OF_CELLS = TOTAL_GRID_CELL_NUMBER;

  private IMAGE_SIZE_LIMIT = 5760000;

  private currentPath: string | null = null;

  private tiff: TiffFile | null = null;

  private image: TiffImage | null = null;

  private imageSizes: {
    [key: number]: {
      w: number;
      h: number;
    };
  } = {};

  private bbox: {
    longMin: number;
    latMin: number;
    longMax: number;
    latMax: number;
  } | null = null;

  private requestedBox: {
    longMin: number;
    latMin: number;
    longMax: number;
    latMax: number;
  } | null = null;

  private meta: {
    title: string;
    noData: number;
  } = {
    title: 'Unknown',
    noData: 0,
  };

  private async setup(url: string) {
    this.currentPath = url;

    this.tiff = await fromUrl(url);

    // Number of overlays
    const count = await this.tiff!.getImageCount();

    // Fallback to the smallest image, because size is unknown
    let targetIndex = count - 1;

    this.imageSizes = {};

    for (let i = 0; i < count; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const img = await this.tiff!.getImage(i);

      // It is always needed to read the original (largest image) to get correct meta
      if (i === 0) {
        const bbox = img.getBoundingBox();

        this.bbox = {
          longMin: bbox[0],
          latMin: bbox[1],
          longMax: bbox[2],
          latMax: bbox[3],
        };

        this.extractMetadata(img.fileDirectory);
      }
      try {
        const w = img.getWidth();
        const h = img.getHeight();
        const s = w * h;
        this.imageSizes[i] = { w, h };
        if (s <= this.IMAGE_SIZE_LIMIT) {
          targetIndex = i;
        }
      } catch {
        break;
      }
    }

    this.image = await this.tiff!.getImage(targetIndex);
  }

  async read(
    { long1, lat1, long2, lat2 }: GeoBoxBounds,
    url: string,
    scalingFactor = 1,
  ): Promise<{
    cellSizeX: number;
    cellSizeY: number;
    numberOfCellsX: number;
    numberOfCellsY: number;
    imageExtent: Extent;
    metadata: {
      title: string;
      noData: number;
    };
    minValue: number | null;
    maxValue: number | null;
    data: DataLayer;
  } | null> {
    if (url !== this.currentPath) {
      await this.setup(url);
    }

    this.requestedBox = {
      longMin: lat1,
      longMax: lat2,
      latMin: lat1,
      latMax: lat2,
    };
    const p1Geo = this.calculateCorrectedPoint(long1, lat1);
    const p2Geo = this.calculateCorrectedPoint(long2, lat2);
    if (!p1Geo || !p2Geo) {
      throw Error();
    }

    const vals1 = this.calcPixelValues({ lat: p1Geo.lat, long: p1Geo.long });
    const vals2 = this.calcPixelValues({ lat: p2Geo.lat, long: p2Geo.long });

    if (!vals1 || !vals2 || !this.image) {
      throw Error();
    }

    // Find the largest affordable image
    const count = Object.keys(this.imageSizes).length;
    let nextImageIndex = count - 1;

    for (let i = 0; i < count; i += 1) {
      const s = (vals2.points[i].xPx - vals1.points[i].xPx) * (vals2.points[i].yPx - vals1.points[i].yPx);
      if (s <= this.IMAGE_SIZE_LIMIT) {
        nextImageIndex = i;
        break;
      }
    }

    const p1Px = vals1.points[nextImageIndex];
    const p2Px = vals2.points[nextImageIndex];

    this.image = await this.tiff!.getImage(nextImageIndex);

    const imageExtent: Extent = [p1Geo.long, p1Geo.lat, p2Geo.long, p2Geo.lat];
    const imageWindow: RasterExtent = [p1Px.xPx, p1Px.yPx, p2Px.xPx, p2Px.yPx];

    if (!(imageWindow[2] - imageWindow[0]) || !(imageWindow[3] - imageWindow[1])) {
      return null;
    }

    const { numberOfCellsX, numberOfCellsY } = GridBuilder.calcSquareCellForWindow(
      imageWindow,
      this.TOTAL_NUMBER_OF_CELLS,
    );

    const data = await this.image.readRasters({
      width: numberOfCellsX,
      height: numberOfCellsY,
      window: imageWindow,
    });

    const { longSide, latSide } = GridBuilder.calcCellForExtent(imageExtent, numberOfCellsX, numberOfCellsY);

    const offsetLong = longSide / 2;
    const offsetLat = latSide / 2;

    const plotData: { val: number; long: number; lat: number }[] = [];

    let minValue: number | null = null;
    let maxValue: number | null = null;

    // TODO: support for rgb ?
    for (let i = 0; i < data[0].length; i += 1) {
      const row = Math.floor(i / numberOfCellsX);
      const col = i % numberOfCellsX;

      const rawVal = data[0][i];

      if (rawVal >= 0 && rawVal !== this.meta.noData && !Number.isNaN(rawVal)) {
        const val = Math.round(rawVal * scalingFactor);
        plotData.push({
          val,
          lat: p2Geo.lat + (row * latSide + offsetLat),
          long: p1Geo.long + col * longSide + offsetLong,
        });
        if (minValue === null || val < minValue) {
          minValue = val;
        }
        if (maxValue === null || val > maxValue) {
          maxValue = val;
        }
      }
    }

    return {
      data: plotData,
      minValue,
      maxValue,
      metadata: this.meta,
      cellSizeX: longSide,
      cellSizeY: latSide,
      numberOfCellsX,
      numberOfCellsY,
      imageExtent,
    };
  }

  private extractMetadata(meta: FileDirectory) {
    const parser = new DOMParser();
    // const doc = parser.parseFromString(meta.GDAL_METADATA, 'text/html');
    // const title = (doc.querySelector('[name="ATTRIBUTE_TITLE"]') as HTMLDivElement).innerText;
    const noData = Number.parseFloat(meta.GDAL_NODATA);
    this.meta = {
      title: 'default',
      noData,
    };
  }

  private calculateCorrectedPoint(long: number, lat: number): { long: number; lat: number } | null {
    if (!this.bbox) {
      return null;
    }
    const { longMin, longMax, latMin, latMax } = this.bbox;
    const nextLong = (() => {
      if (long <= longMin) {
        return longMin;
      }
      if (long >= longMax) {
        return longMax;
      }
      return long;
    })();

    const nextLat = (() => {
      if (lat <= latMin) {
        return latMin;
      }
      if (lat >= latMax) {
        return latMax;
      }
      return lat;
    })();

    return {
      long: nextLong,
      lat: nextLat,
    };
  }

  // TODO: simplify, maybe get rid of corrected geo point
  private calcPixelValues({
    long,
    lat,
  }: {
    long: number;
    lat: number;
  }): {
    points: {
      [key: number]: {
        xPx: number;
        yPx: number;
      };
    };
  } | null {
    if (!this.bbox || !this.requestedBox) {
      return null;
    }
    const { longMin, longMax, latMin, latMax } = this.bbox;
    const bboxWidth = longMax - longMin;
    const bboxHeight = latMax - latMin;

    // Coefficients to find appropriate pixel from provided coordinate
    const kX = (long - longMin) / bboxWidth;
    const kY = (lat - latMin) / bboxHeight;

    const points: { [key: string]: { xPx: number; yPx: number } } = {};

    Object.keys(this.imageSizes).forEach((key) => {
      const { h } = this.imageSizes[key];
      let offsetY = 0;

      if (latMax < this.requestedBox!.latMax && latMin < this.requestedBox!.latMin) {
        offsetY = (h * (this.requestedBox!.latMin - latMin)) / bboxHeight;
      }
      if (latMax > this.requestedBox!.latMax && latMin < this.requestedBox!.latMin) {
        offsetY = (h * (this.requestedBox!.latMin + this.requestedBox!.latMax - latMin - latMax)) / bboxHeight;
      }
      if (latMax > this.requestedBox!.latMax && latMin > this.requestedBox!.latMin) {
        offsetY = (h * (this.requestedBox!.latMax - latMax)) / bboxHeight;
      }

      points[key] = {
        xPx: Math.floor(this.imageSizes[key].w * kX),
        yPx: Math.floor(this.imageSizes[key].h * kY) - Math.floor(offsetY),
      };
    });

    return {
      points,
    };
  }
}
