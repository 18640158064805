import { computed } from '@vue/composition-api';

import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';
import usePieces from '@/store/projections/usePieces';

export default function useChart() {
  const store = injectStrict(StoreKey);
  const { model } = usePieces();

  return {
    model: computed(() => ({
      opacity: store.model.chart.opacity,
      ranges: model.value,
    })),
  };
}
