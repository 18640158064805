import { DataLayer } from '@/types/data';
import { EChartsOption } from 'echarts';
import { CustomSeriesRenderItemAPI, CustomSeriesRenderItemParams } from 'echarts/types/src/chart/custom/install';

type Ranges = { min: number; max: number; color: string }[];

export type RawBinnedData = {
  data: DataLayer;
  cellSizeX: number;
  cellSizeY: number;
  opacity: number;
  ranges: Ranges;
};

export default class BinnedMapChart {
  private selectedDataIndex: number | null = null;

  cachedOptions: EChartsOption | null = null;

  static convertData(data: DataLayer): [number, number, number][] {
    return data.map((el) => [el.long, el.lat, el.val]);
  }

  static generatePieces(
    ranges: Ranges,
  ): {
    colors: string[];
    pieces: { min: number; max: number }[];
  } {
    return {
      colors: ranges.map((el) => el.color),
      pieces: ranges.map((el) => ({ min: el.min, max: el.max })),
    };
  }

  selectData(index: number | null) {
    this.selectedDataIndex = index;
  }

  createOptions({ data, cellSizeX, cellSizeY, opacity, ranges }: RawBinnedData): EChartsOption {
    const halfCellSizeX = cellSizeX / 2;
    const halfCellSizeY = cellSizeY / 2;

    const piecesData = BinnedMapChart.generatePieces(ranges);
    const { pieces, colors } = piecesData;

    const renderItem = (params: CustomSeriesRenderItemParams, api: CustomSeriesRenderItemAPI) => {
      const centerLong = Number(api.value(0));
      const centerLat = Number(api.value(1));

      const [pointLeftTop, pointRightBottom] = [
        api.coord([centerLong - halfCellSizeX, centerLat + halfCellSizeY]),
        api.coord([centerLong + halfCellSizeX, centerLat - halfCellSizeY]),
      ];

      // Skip filtered cells
      if (api.visual('color') === 'rgba(0,0,0,0)') {
        return null;
      }

      const color = api.visual('color') as string;

      const style = (() => {
        const s: any = {
          // TODO: make more reliable
          fill: color.replace(/,1\)/gi, `,${opacity / 100})`),
          lineWidth: 3,
        };
        // TODO: use hacked type?
        if ((<any>params).dataIndex === this.selectedDataIndex) {
          s.stroke = 'rgba(56, 56, 56, 1)';
        } else {
          s.stroke = 'rgba(0, 0, 0, 0)';
        }
        return s;
      })();

      return {
        type: 'rect',
        shape: {
          x: pointLeftTop[0],
          y: pointLeftTop[1],
          width: pointRightBottom[0] - pointLeftTop[0],
          height: pointRightBottom[1] - pointLeftTop[1],
        },
        style,
      };
    };

    const options = {
      series: [
        {
          type: 'custom',
          coordinateSystem: 'openlayers',
          renderItem,
          animation: false,
          encode: {
            tooltip: 2,
          },
          data: BinnedMapChart.convertData(data),
        },
      ],
      visualMap: [
        {
          show: false,
          type: 'piecewise',
          inverse: true,
          seriesIndex: 0,
          borderWidth: 2,
          dimension: 2,
          pieces,
          inRange: {
            color: colors,
            opacity,
          },
        },
      ],
    } as EChartsOption;

    this.cachedOptions = { ...options };

    return options;
  }
}
