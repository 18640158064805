




/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { TOTAL_GRID_CELL_NUMBER } from '@/constants';

export default defineComponent({
  name: 'TheMosaicLoader',
  props: {
    count: {
      type: Number as PropType<number>,
      required: false,
      default: TOTAL_GRID_CELL_NUMBER,
    },
  },
  setup(props) {
    const canvas = ref();

    watch(canvas, (element, oldElement, onInvalidate) => {
      const ctx = canvas.value.getContext('2d');

      let w: number | null = null;
      let h: number | null = null;

      let { count } = props;

      let cellSize: number | null = null;
      let rows: number | null = null;
      let cols: number | null = null;

      const seed: { [key: string]: number } = {};

      updateDrawSettings();

      function updateDrawSettings() {
        w = canvas.value.parentElement.clientWidth;
        h = canvas.value.parentElement.clientHeight;
        count = props.count;
        cellSize = Math.sqrt((w! * h!) / count);
        rows = Math.ceil(w! / cellSize);
        cols = Math.ceil(h! / cellSize);
        ctx.canvas.width = w;
        ctx.canvas.height = h;
      }

      let raf: number | null = null;

      function draw() {
        raf = requestAnimationFrame(draw);

        const nextW = canvas.value.parentElement.clientWidth;
        const nextH = canvas.value.parentElement.clientHeight;

        if (nextW !== w || nextH !== h || count !== props.count) {
          updateDrawSettings();
        }

        ctx.clearRect(0, 0, w!, h!);

        for (let i = 0; i < cols!; i += 1) {
          for (let j = 0; j < rows!; j += 1) {
            const key = `${i}${j}`;
            if (!seed[key]) {
              seed[key] = Math.random();
            }
            const opacity = 0.35 * (1 + Math.sin(seed[key]));
            ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
            ctx.fillRect(j * cellSize!, i * cellSize!, cellSize!, cellSize!);
            seed[key] += (Math.random() * Math.random()) / 4;
          }
        }
      }
      draw();

      onInvalidate(() => {
        cancelAnimationFrame(raf!);
      });
    });

    return {
      canvas,
    };
  },
});
