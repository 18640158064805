import { reactive } from '@vue/composition-api';

import { MAP_ZOOM_LIMITS } from '@/constants';
import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';

const model = reactive({
  zoom: {
    current: MAP_ZOOM_LIMITS[0],
    min: MAP_ZOOM_LIMITS[0],
    max: MAP_ZOOM_LIMITS[1],
  },
});

export default function useZoomPicker() {
  const store = injectStrict(StoreKey);

  model.zoom.current = store.init.share.zoom;

  const zoomIn = () => {
    if (model.zoom.current < model.zoom.max) {
      model.zoom.current += 1;
    }
  };

  const zoomOut = () => {
    if (model.zoom.current > model.zoom.min) {
      model.zoom.current -= 1;
    }
  };

  const setZoom = (value: number) => {
    if (value >= model.zoom.min && value <= model.zoom.max) {
      model.zoom.current = value;
    }
  };

  return {
    model,
    zoomIn,
    zoomOut,
    setZoom,
  };
}
