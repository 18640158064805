var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full text-dark-1 p-3"},[_c('div',{staticClass:"h-full relative rounded-lg overflow-hidden"},[_c('div',{staticClass:"rounded-lg overflow-hidden h-full transform transition-all duration-300",class:{ '-translate-y-40': _vm.layoutModel.isAreaOpen, 'translate-x-80': _vm.layoutModel.isSettingsWidgetOpen }},[_c('div',{ref:"mapContainerElement",staticClass:"h-full rounded-lg"}),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-to-class":"animate__animated animate__fadeOut"}},[(_vm.overviewLoader.isLoading)?_c('the-mosaic-loader',{staticClass:"absolute top-0 left-0 pointer-events-none"}):_vm._e()],1),_c('div',{staticClass:"space-x-2 absolute top-5 left-5 flex pr-5 transform transition-all duration-300",class:{
          'translate-y-40': _vm.layoutModel.isAreaOpen,
          'pr-80': _vm.layoutModel.isSettingsWidgetOpen,
        }},[_c('toggle-settings-button'),_c('share-map-view'),_c('recent-data-sources')],1),_c('div',{staticClass:"absolute top-10 right-10 h-80 w-10 transform transition-all duration-300",class:{ 'translate-y-40': _vm.layoutModel.isAreaOpen, '-translate-x-80': _vm.layoutModel.isSettingsWidgetOpen }},[_c('zoom-picker',{attrs:{"value":_vm.mapViewModel.zoom},on:{"zoom":_vm.onZoom}}),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn animate__faster","leave-to-class":"animate__animated animate__fadeOut animate__faster","mode":"out-in"}},[(_vm.geotiffModel.cellSize)?_c('div',{key:_vm.geotiffModel.cellSize,staticClass:"mt-2 border-b-2 text-center border-white text-xs font-semibold ruler pb-1 whitespace-nowrap",attrs:{"title":"Cell side (km)"}},[_vm._v(" "+_vm._s(_vm.geotiffModel.cellSize)+" km ")]):_vm._e()])],1)],1),_c('div',{staticClass:"mask-tl bg-transparent absolute -top-2 -left-2 w-10 h-10 rounded-lg pointer-events-none transform transition-all duration-300",class:{
        'translate-x-80': _vm.layoutModel.isSettingsWidgetOpen,
      }}),_c('div',{staticClass:"mask-br bg-transparent absolute -bottom-2 -right-2 w-10 h-10 rounded-lg pointer-events-none transform transition-all duration-300",class:{
        '-translate-y-40': _vm.layoutModel.isAreaOpen,
      }})]),_c('settings-widget'),_c('the-info-area',{attrs:{"parent-container-id":_vm.parentContainerId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }