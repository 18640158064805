


































































































































import { computed, defineComponent } from '@vue/composition-api';
import VueSlider from 'vue-slider-component';

import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';
import usePieces from '@/store/projections/usePieces';
import PrimaryInput from '@/components/PrimaryInput';
import useSelectedFile from '@/store/projections/useSelectedFile';

export default defineComponent({
  name: 'RangePicker',
  components: {
    VueSlider,
    PrimaryInput,
  },
  setup() {
    const store = injectStrict(StoreKey);
    const { model: piecesModel } = usePieces();
    const { model: selectedFileModel } = useSelectedFile();

    const factor = () => selectedFileModel.value.file?.scaling.factor || 1;

    const valuesToolTipFormatter = (val: number) => val / factor();

    const onBlur = (e: FocusEvent) => {
      if (!e.target) {
        return;
      }
      const target = e.target as HTMLInputElement;
      const value = +target.value;
      if (target.name === 'divisions') {
        if (
          value >= store.model.chart.piecewise.validated.count.min &&
          value <= store.model.chart.piecewise.validated.count.max
        ) {
          store.actions.chart.updateRawPiecesCount(value);
          store.actions.chart.updatePiecesCount(value);
        } else {
          store.actions.chart.updateRawPiecesCount(store.model.chart.piecewise.validated.count.current);
        }
      }
      if (
        (target.name === 'min' || target.name === 'max') &&
        store.model.chart.piecewise.validated.filter.range &&
        store.model.geotiff.valuesRange
      ) {
        if (
          target.name === 'min' &&
          value * factor() >= store.model.geotiff.valuesRange[0] &&
          value * factor() <= store.model.chart.piecewise.validated.filter.range[1]
        ) {
          store.actions.chart.updateRawRangeFilter([
            value,
            store.model.chart.piecewise.validated.filter.range[1] / factor(),
          ]);
          store.actions.chart.updateRangeFilter([
            value * factor(),
            store.model.chart.piecewise.validated.filter.range[1],
          ]);
        } else {
          store.actions.chart.updateRawRangeFilter([
            store.model.chart.piecewise.validated.filter.range[0] / factor(),
            store.model.chart.piecewise.validated.filter.range[1] / factor(),
          ]);
        }
        if (
          target.name === 'max' &&
          value * factor() <= store.model.geotiff.valuesRange[1] &&
          value * factor() >= store.model.chart.piecewise.validated.filter.range[0]
        ) {
          store.actions.chart.updateRawRangeFilter([
            store.model.chart.piecewise.validated.filter.range[0] / factor(),
            value,
          ]);
          store.actions.chart.updateRangeFilter([
            store.model.chart.piecewise.validated.filter.range[0],
            value * factor(),
          ]);
        } else {
          store.actions.chart.updateRawRangeFilter([
            store.model.chart.piecewise.validated.filter.range[0] / factor(),
            store.model.chart.piecewise.validated.filter.range[1] / factor(),
          ]);
        }
      }
    };

    return {
      model: computed(() => {
        const valuesRange = store.model.geotiff.valuesRange || [0, 0];
        const valuesMarks = {
          [valuesRange[0]]: `${valuesRange[0] / factor()}`,
          [valuesRange[1]]: `${valuesRange[1] / factor()}`,
        };
        const scaledRanges = (piecesModel.value || []).map((el) => ({
          min: el.min / factor(),
          max: el.max / factor(),
          color: el.color,
        }));
        return {
          scaledRanges,
          piecewise: store.model.chart.piecewise,
          valuesRange,
          valuesMarks,
        };
      }),
      onBlur,
      updatePalette: store.actions.chart.updatePalette,
      valuesToolTipFormatter,
    };
  },
});
