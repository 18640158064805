


















import { defineComponent } from '@vue/composition-api';

import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';

export default defineComponent({
  name: 'DataSourcePicker',
  components: {},
  setup() {
    const store = injectStrict(StoreKey);

    return {
      model: store.model.sources,
      selectItem: store.actions.sources.select,
    };
  },
});
