type OverviewLoader = {
  isLoading: boolean;
};

export default function overviewLoader() {
  const model: OverviewLoader = {
    isLoading: true,
  };

  const open = () => {
    model.isLoading = true;
  };

  const close = () => {
    model.isLoading = false;
  };

  return {
    model: {
      overviewLoader: model,
    },
    actions: {
      overviewLoader: {
        open,
        close,
      },
    },
  };
}
