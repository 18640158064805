import { getCOGs } from '@/api';
import { COGItem } from '@/types/data';

type Sources = {
  all: COGItem[];
  mode: 'single' | 'multiple';
  selected: string[];
  recent: string[];
};

const MAX_HISTORY_LENGTH = 3;

export default function sources(selected?: Sources['selected']) {
  const model: Sources = {
    all: [],
    mode: 'single',
    selected: [],
    recent: [],
  };

  const select = (id: string) => {
    if (model.mode === 'single') {
      model.selected = [id];
    }
  };

  const addRecent = (itemId: string) => {
    const sameItem = model.recent.find((el) => el === itemId);
    if (sameItem) {
      return;
    }
    if (model.recent.length < MAX_HISTORY_LENGTH) {
      model.recent.unshift(itemId);
    } else {
      model.recent.splice(0, 1, itemId);
    }
  };

  // Init
  getCOGs()
    .then((res) => {
      model.all = res;

      // TODO: support multi source
      const singleSource = selected && selected[0];
      const defaultSelected = singleSource && res.find((el) => el.id === singleSource) ? singleSource : res[0].id;
      model.selected.push(defaultSelected);
    })
    .catch(() => {
      model.all = [];
      model.selected = [];
    });

  return {
    model: {
      sources: model,
    },
    actions: {
      sources: {
        select,
        addRecent,
      },
    },
  };
}
