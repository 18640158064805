import { COGItem } from '@/types/data';
import { map } from 'rxjs/operators';

import { camelize } from '@/lib/camelize';
import { createHttpObservable } from '@/utils/observables';

export type HistoryResponse = {
  lat: number;
  lng: number;
  queryDate: string;
  smapMeasurements: {
    [key: string]: number | null;
  };
};

export function getAttributeHistory(
  url: string,
  long: number,
  lat: number,
  date: string,
  daysPrior = 80,
  daysPost = 10,
) {
  return createHttpObservable(
    `${url}?date=${date}&days_prior=${daysPrior}&days_post=${daysPost}&lat=${lat}&lng=${long}`,
  ).pipe(map((body: any) => camelize(body) as HistoryResponse));
}

export function getCOGs() {
  return new Promise<COGItem[]>((resolve) => {
    setTimeout(() => {
      resolve([
        {
          id: '0',
          title: 'FAW prediction model by PSU',
          shortTitle: 'FAW prediction model',
          url: 'https://pv-earth.s3.amazonaws.com/models/faw/kenya_model_faw_predictions_xgboost_2021-04-05.tif',
          scaling: {
            applied: false,
            factor: 100,
          },
        },
        {
          id: '1',
          title: '15-days forecast rainfall, mm, 2021-04-22 20:22',
          shortTitle: '15-days forecast rainfall, mm',
          url: 'https://pv-earth.s3.amazonaws.com/chc/precip_mean/data-mean_20210422_20210506.tif',
          units: 'mm',
          palette: {
            inverted: true,
          },
        },
        {
          id: '2',
          title: 'soil moisture volumetric percent at depth of 0-10cm',
          shortTitle: 'soil moisture',
          url: 'https://pv-earth.s3.amazonaws.com/soil/soil_moisture/east_africa/4326/2021/04/01.tif',
          scaling: {
            applied: false,
            factor: 100,
          },
          historyURL: 'https://earth.plantvillage.psu.edu/soil/moisture',
        },
        {
          id: '3',
          title: 'pH measured in water at soil depth of 0-5cm',
          shortTitle: 'pH measured at soil depth of 0-5cm',
          url: 'https://pv-earth.s3.amazonaws.com/soil/isric/ph/PHIHOX_M_sl1_250m_ll.tif',
          scaling: {
            applied: true,
            factor: 10,
          },
        },
        {
          id: '4',
          title: 'organic carbon content at soil depth of 0-5cm',
          shortTitle: 'OC, g/kg',
          url: 'https://pv-earth.s3.amazonaws.com/soil/isric/organic_carbon/ORCDRC_M_sl2_250m_ll.tif',
          units: 'g/kg',
        },
      ]);
    }, 1000);
  });
}
