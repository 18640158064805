import * as queryString from 'query-string';

import { MAP_ZOOM_LIMITS, PALETTES, PIECES_COUNT_RANGE } from '@/constants';
import { PaletteName } from '@/types/data';

export type ShareObject = {
  long: number;
  lat: number;
  zoom: number;
  opacity: number;
  palette: PaletteName;
  pieces: number;
  filter?: [number, number];
  sources?: string[];
};

class ShareService {
  static sync(url: string) {
    window.history.replaceState({}, document.title, `${url}`);
  }

  static getDefaultConfig(): ShareObject {
    return {
      ...ShareService.createDefaultConfig(),
      ...ShareService.extractValidConfigFromUrl(),
    };
  }

  private static createDefaultConfig(): ShareObject {
    return {
      // Busia County
      long: 34.2422,
      lat: 0.4347,
      zoom: 103,
      opacity: 30,
      palette: 'Grass',
      pieces: PIECES_COUNT_RANGE[1],
    };
  }

  private static extractValidConfigFromUrl() {
    const config: Partial<ShareObject> = {};

    const {
      long: maybeLong,
      lat: maybeLat,
      zoom: maybeZoom,
      opacity: maybeOpacity,
      palette: maybePalette,
      pieces: maybePieces,
      filter: maybeFilter,
      sources: maybeSources,
    } = queryString.parse(window.location.search);

    if (typeof maybeLong === 'string') {
      const long = Number.parseFloat(maybeLong);
      if (!Number.isNaN(long) && long >= -180 && long <= 180) {
        config.long = long;
      }
    }
    if (typeof maybeLat === 'string') {
      const lat = Number.parseFloat(maybeLat);
      if (!Number.isNaN(lat) && lat >= -90 && lat <= 90) {
        config.lat = lat;
      }
    }
    if (typeof maybeZoom === 'string') {
      const zoom = Number.parseFloat(maybeZoom);

      if (!Number.isNaN(zoom) && zoom >= MAP_ZOOM_LIMITS[0] && zoom <= MAP_ZOOM_LIMITS[1]) {
        config.zoom = zoom;
      }
    }
    if (typeof maybeOpacity === 'string') {
      const opacity = Number.parseFloat(maybeOpacity);

      if (!Number.isNaN(opacity) && opacity >= 0 && opacity <= 100) {
        config.opacity = opacity;
      }
    }

    if (typeof maybePalette === 'string') {
      // TODO: find a better way
      if (([...PALETTES] as string[]).includes(maybePalette)) {
        config.palette = maybePalette as PaletteName;
      }
    }

    if (typeof maybePieces === 'string') {
      const pieces = Number.parseFloat(maybePieces);

      if (!Number.isNaN(pieces) && pieces >= PIECES_COUNT_RANGE[0] && pieces <= PIECES_COUNT_RANGE[1]) {
        config.pieces = pieces;
      }
    }

    if (Array.isArray(maybeFilter) && maybeFilter.length === 2) {
      const filter = maybeFilter.map(Number.parseFloat);
      if (!filter.some(Number.isNaN)) {
        config.filter = filter as [number, number];
      }
    }

    if (Array.isArray(maybeSources) && maybeSources.length > 0 && maybeSources.length <= 2) {
      config.sources = maybeSources;
    }
    if (typeof maybeSources === 'string') {
      config.sources = [maybeSources];
    }

    return config;
  }
}

export default ShareService;
