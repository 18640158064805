import { computed } from '@vue/composition-api';
import * as queryString from 'query-string';

import { ShareObject } from '@/services/shareService';
import injectStrict from '@/utils/injectStrict';
import { StoreKey } from '@/store';

export default function useShareSettings() {
  const store = injectStrict(StoreKey);

  return {
    model: computed(() => {
      const config: ShareObject = {
        long: store.model.overview.center.long,
        lat: store.model.overview.center.lat,
        zoom: store.model.overview.zoom,
        opacity: store.model.chart.opacity,
        palette: store.model.chart.piecewise.palette.current.name,
        pieces: store.model.chart.piecewise.validated.count.current,
        filter: store.model.chart.piecewise.validated.filter.range
          ? [...store.model.chart.piecewise.validated.filter.range]
          : undefined,
        sources: [...store.model.sources.selected],
      };
      return {
        url: `${window.location.origin + window.location.pathname}?${queryString.stringify(config)}`,
      };
    }),
  };
}
