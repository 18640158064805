
































































import { defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api';
import { combineLatest, interval, of, Subject } from 'rxjs';
import { debounce, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { getCenter } from 'ol/extent';

// API
import COGExtractor from '@/api/COGExtractor';
// Map/Echarts
import useTheEchartsLayerObserver from '@/pages/StressMapPage/useTheEchartsLayerObserver';
// Components
import TheMosaicLoader from '@/components/TheMosaicLoader';
import TheInfoArea from '@/pages/StressMapPage/components/TheInfoArea';
import SettingsWidget from '@/pages/StressMapPage/components/SettingsWidget';
import ToggleSettingsButton from '@/pages/StressMapPage/components/ToggleSettingsButton';
import RecentDataSources from '@/pages/StressMapPage/components/RecentDataSources';
import ZoomPicker from '@/pages/StressMapPage/components/ZoomPicker';
import ShareMapView from '@/pages/StressMapPage/components/ShareMapView';
// Store
import { StoreKey } from '@/store';
import injectStrict from '@/utils/injectStrict';
import useTheInfoArea from '@/pages/StressMapPage/components/TheInfoArea/useTheInfoArea';
import useTheModal from '@/pages/StressMapPage/components/TheModal/useTheModal';
import useChart from '@/store/projections/useChart';
import useLayout from '@/store/ui/useLayout';
import useShareSettings from '@/store/ui/useShareSettings';
import useSelectedFile from '@/store/projections/useSelectedFile';

export default defineComponent({
  name: 'StressMapPage',
  props: {
    parentContainerId: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  components: {
    TheInfoArea,
    SettingsWidget,
    TheMosaicLoader,
    ToggleSettingsButton,
    RecentDataSources,
    ZoomPicker,
    ShareMapView,
  },
  setup() {
    const store = injectStrict(StoreKey);
    // UI
    const { model: layoutModel } = useLayout();
    const { open: openInfo, close: closeInfo } = useTheInfoArea();
    const { open: openModal } = useTheModal();

    const { model: chartModel } = useChart();

    const { model: shareSettingsModel } = useShareSettings();

    const { model: selectedFileModel } = useSelectedFile();

    // Map
    const mapContainerElement = ref<HTMLDivElement>();
    const onChartClick = (params: {
      dataIndex: number;
      // long, lat, value
      value: [number, number, number];
    }) => {
      const long = params.value[0];
      const lat = params.value[1];
      store.actions.area.update({
        index: params.dataIndex,
        long,
        lat,
        val: params.value[2],
      });
      store.actions.area.updateHistory(long, lat, selectedFileModel.value.file?.historyURL);
      openInfo();
    };
    const {
      init: initTheEchartsLayer,
      moveend$,
      movestart$,
      draw: drawEchartsLayer,
      clearSelection,
      onZoom,
    } = useTheEchartsLayerObserver(mapContainerElement, onChartClick);

    watch(
      () => layoutModel.value.isAreaOpen,
      (isAreaOpen) => {
        if (!isAreaOpen) {
          clearSelection();
        }
      },
    );
    // COG extractor
    const cogExtractor = new COGExtractor();

    // Init
    onMounted(() => {
      initTheEchartsLayer(JSON.parse(JSON.stringify(store.init.share)));
    });

    // Update url
    watch(shareSettingsModel, ({ url }) => {
      store.services.share.sync(url);
    });

    // UI streams
    // Data url, requires new file (reinit)
    const dataSource$ = new Subject<{ url: string; scalingFactor: number }>();

    watch(selectedFileModel, (nextSelectedFileModel) => {
      if (nextSelectedFileModel.file) {
        // We add every used file to recent files in order to have fast access to them later
        store.actions.sources.addRecent(nextSelectedFileModel.file.id);
        // Notify that the current file has changed
        dataSource$.next({
          url: nextSelectedFileModel.file.url,
          scalingFactor: nextSelectedFileModel.file.scaling.applied ? 1 : nextSelectedFileModel.file.scaling.factor,
        });
      }
    });

    // Chart settings change, just redraw chart
    const chartSettings$ = new Subject<typeof chartModel.value>();
    watch(chartModel, (nextChart) => {
      chartSettings$.next(nextChart);
    });

    watch(mapContainerElement, (target, _, onInvalidate) => {
      if (!target) return;

      const chart$ = chartSettings$.pipe(debounce(() => interval(400)));

      const loader$ = movestart$.subscribe(() => {
        store.actions.overviewLoader.open();
      });

      const draw$ = chart$
        .pipe(
          withLatestFrom(
            combineLatest([moveend$, dataSource$]).pipe(
              debounce(() => interval(100)),
              tap(() => {
                store.actions.overviewLoader.open();
                closeInfo();
              }),
              map(([event, { url, scalingFactor }]) => {
                const extent = event.map.getView().calculateExtent(event.map.getSize());
                const zoom = event.map.getView().getZoom() as number;
                const center = getCenter(extent) as [number, number];
                return { url, scalingFactor, extent, zoom, center };
              }),
              tap(({ zoom, center }) => {
                store.actions.overview.update({
                  zoom: Math.floor(zoom * 10),
                  center: { long: center[0], lat: center[1] },
                });
              }),
              switchMap(({ url, extent, scalingFactor }) =>
                cogExtractor.read(
                  {
                    long1: extent[0],
                    lat1: extent[1],
                    long2: extent[2],
                    lat2: extent[3],
                  },
                  url,
                  scalingFactor,
                ),
              ),
              tap((res) => {
                if (res && res.minValue !== null && res.maxValue !== null) {
                  store.actions.geotiff.updateValuesRange([res.minValue, res.maxValue]);
                  store.actions.geotiff.updateCellSize(Math.round(res.cellSizeX * 110.574));
                } else {
                  store.actions.overviewLoader.close();
                  openModal();
                }
              }),
            ),
          ),
          exhaustMap(([c, d]) => {
            if (!d || !c) {
              return of(null);
            }
            if (c.ranges === undefined) {
              return of(null);
            }
            return drawEchartsLayer({
              data: d.data,
              cellSizeX: d.cellSizeX,
              cellSizeY: d.cellSizeY,
              opacity: c.opacity,
              ranges: [...c.ranges],
            });
          }),
          tap(() => {
            store.actions.overviewLoader.close();
          }),
        )
        .subscribe();

      onInvalidate(() => {
        draw$.unsubscribe();
        loader$.unsubscribe();
      });
    });

    return {
      mapContainerElement,
      layoutModel,
      overviewLoader: store.model.overviewLoader,
      onZoom,
      mapViewModel: store.model.overview,
      geotiffModel: store.model.geotiff,
    };
  },
});
