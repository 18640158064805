export default class GridBuilder {
  static calcSquareCellForWindow(window: [number, number, number, number], total: number) {
    const width = window[2] - window[0];
    const height = window[3] - window[1];

    const squareSide = Math.sqrt((width * height) / total);
    const numberOfCellsX = Math.ceil(width / squareSide);
    const numberOfCellsY = Math.ceil(height / squareSide);
    return {
      squareSide,
      numberOfCellsX,
      numberOfCellsY,
    };
  }

  static calcCellForExtent(extent: [number, number, number, number], countLong: number, countLat: number) {
    const deltaLong = extent[2] - extent[0];
    const deltaLat = extent[1] - extent[3];
    const longSide = deltaLong / countLong;
    const latSide = deltaLat / countLat;
    return {
      longSide,
      latSide,
    };
  }
}
